export const black = "#000000";
export const white = "#FFFFFF";
export const white50 = "rgba(255, 255, 255, 0.5)"
export const gray87 = "#212121";
export const gray54 = "#757575";
export const gray30 = "#B3B3B3";
export const gray10 = "#E6E6E6";
export const hanPurple = "#4F3BEB";
export const midnightBlue = "#170C6F";
export const tartOrange = '#FF312E';
export const cultured = '#F7F8FA';
export const amber = '#FFBE0A';
export const sunglow = '#FFCD40';