
/** @jsx jsx */
import { jsx } from "@emotion/react"
import { H3, Text } from "./lib"
import * as colors from "../styles/colors"
import * as mq from "../styles/media-queries"

const ExperienceBubble = ({stat, title, fColor, shadow, boxSize, mSize}) => {
  return (
    <div css={{
      position: 'relative',
      width: 'fit-content',
      margin: '-5% 0',
      display: 'flex',
      alignItems: 'center',
      [mq.large]: {
        margin: '0 -20px',
        flexDirection: 'column',
      }
    }}>
      <div css={{
        maxWidth: mSize,
        maxHeight: mSize,
        aspectRatio: '1/1',
        width: boxSize + 'vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.white,
        borderRadius: '50%',
        filter: shadow,
        [mq.medium]: {
          width: (boxSize - 8) + 'vw',
          height: (boxSize - 8) + 'vw',
        }
      }}>
        <p css={{
          fontSize:'34px',
          fontFamily: '"Poppins", sans-serif',
          fontWeight: '600',
          fontStyle: 'italic',
          color: fColor,
          [mq.large]: {
            fontSize: '54px'
          }
        }}>
          {stat}
        </p>
      </div>
      <p css={{
        width: '150px',
        fontWeight: '600',
        color: fColor,
        [mq.large]: {
          width: '100%',
          position: 'static',
          textAlign: 'center',
          margin: '20px auto',
        }
      }}>{title}</p>
    </div>
  )
};

export default function Experience()
 {
  return (
    <section 
      id="experience"
      className="max-width"
      css={{
        padding: '80px 30px 0',
        textAlign: 'center', 
        marginBottom: '180px'
    }}>
      <div css={{marginBottom: '60px'}}>
        <H3>Experience</H3>
        <Text>Roman had the opportunity to inspire more than 6,000 people around the world.</Text>
      </div>
      <div css={{
        margin: '0 auto',
        width: 'fit-content',
        marginBottom: '100px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [mq.large]: {
          flexDirection: 'row',
        }
      }}>
        <ExperienceBubble stat='14+'     fColor='#FF961C' mSize='192px' boxSize='28' title='Years on Stage'          shadow='drop-shadow(0px 10px 30px rgba(235, 167, 59, 0.4))'/>
        <ExperienceBubble stat='100+'   fColor='#47BEFB' mSize='240px' boxSize='32' title='Keynotes'                 shadow='drop-shadow(0px 15px 30px rgba(71, 190, 251, 0.4))'/>
        <ExperienceBubble stat='1,500'  fColor='#3BEB71' mSize='316px' boxSize='36' title='Max Crowd'                shadow='drop-shadow(0px 15px 30px rgba(59, 235, 113, 0.4))'/>
        <ExperienceBubble stat='6,000+' fColor='#4F3BEB' mSize='390px' boxSize='40' title='Total Audience Inspired'  shadow='drop-shadow(0px 15px 30px rgba(79, 59, 235, 0.3))'/>
      </div>
      <div>
        <Text>Trusted by multidisciplinary companies to revolutionize the working spirit.</Text>
        <div css={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-evenly',
          'figure': {
            width: '45%',
            maxWidth: '304px',
            [mq.large]: {
              width: '35%'
            }
          }
        }}>
          <figure>
            <img src="/img/logos/Evonik-logo.png" alt="Evonik logo"/>
          </figure>
          <figure>
            <img src="/img/logos/TrendOne-logo.png" alt="Trend one logo"/>
          </figure>
          <figure>
            <img src="/img/logos/ManagementCircle-logo.png" alt="Managment Circle logo"/>
          </figure>
          <figure>
            <img src="/img/logos/FutureCandy-logo.png" alt="Future Candy logo"/>
          </figure>
          <figure>
            <img src="/img/logos/Teufelberger-logo.png" alt="Teufelberger logo"/>
          </figure>
        </div>
      </div>
    </section>
  )
}