/** @jsx jsx */
import { jsx } from "@emotion/react"
import * as colors from "../styles/colors"
import * as mq from "../styles/media-queries"
import { ContactModal } from "./contact";
import { Button, H3, H4, Text } from "./lib";

export const Experiences = () => {
  return (
    <section 
      id="tours"
      css={{
        paddingTop: '80px',
        margin: '0 auto',
        maxWidth: '1200px',
    }}>
      <div 
        css={{
          margin: '0 auto 40px',
          padding: '0 30px',
          maxWidth: '800px',
          textAlign: 'center',
        }}
      >
        <H3 css={{margin: '0'}}>Experience the Silicon Valley</H3>
        <Text>Live the Valley like no other, with workshops, meetings, or a complete Silicon Valley Tech Tour. We will support you to enhance your time in the Valley.</Text>
      </div>
      <div 
      css={{
        display: 'flex',
        overflowX: 'auto',
        '& figure': {
          minWidth: '280px',
          padding: '8px',
          maxHeight: '230px',
          '& img': {
            height: '100%',
            objectFit: 'cover',
            borderRadius: '8px',
          }
        }
      }}>
        <figure>
          <img src="/img/photos/Experience-SV-01.jpg" alt="Roman giving a tour in Google's HQ."/>
        </figure>
        <figure>
          <img src="/img/photos/Experience-SV-02.jpg" alt="Roman giving a tour in The Golden Gate."/>
        </figure>
        <figure>
          <img src="/img/photos/Experience-SV-03.jpg" alt="Roman giving a workshop."/>
        </figure>
      </div>
      <div 
        css={{
          width: '100%',
          margin: '20px 0px',
          marginBottom: '180px',
          display: 'flex',
          flexDirection: 'column',
          '& > div': {
            margin: '0 8px 20px',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& h4': {
              margin: '5px 0',
              textAlign: 'center'
            },
            '& p': {
              fontSize: '16px'
            },
            '& button:first-of-class': {
              margin: '12px 0'
            },
          },
          [mq.medium]: {
            flexDirection: 'row',
          }
        }}
      >
        <div 
          css={{
            border: `solid 2px ${colors.hanPurple}`,
            backgroundColor: 'rgba(56,55,225, 5%)',
            borderRadius: '8px'
          }}
        >
          <H4>Addition to your Innovation Journey</H4>
          <Text>Are you planning a trip for yourself or your business to the tech-capital of the world? Do you want to amend and extend your trip with a unique personalized entrepreneurial experience?</Text>
          <Text>We can upgrade your excursion with workshops, meetings, or a complete Silicon Valley Tech Tour. We will support you to enhance your time in the Valley.</Text>
          <ContactModal>
            <Button css={{width: '100%'}}>Contact Roman</Button>
          </ContactModal>
        </div>
        <div 
          css={{
            border: `solid 2px ${colors.sunglow}`,
            backgroundColor: 'rgba(255,205,64, 5%)',
            borderRadius: '8px',
            '& a': {
              textDecoration: 'none',
              width: 'fit-content'
            }
          }}
        >
          <H4>Fully curated  Innovation Field Trip</H4>
          <Text>For German based companies who want to join a complete week from start to finish, or if you want us to organize a full trip customized to your company or team, contact our partners at Future Candy.</Text>
          <Button as='a' href='https://www.futurecandy.com/en/travel-en/' target='blank' variant='futureCandy'>Visit Future Candy</Button>
        </div>
      </div>
    </section>
  )
};