/** @jsx jsx */
import { jsx } from "@emotion/react"
import React from 'react'
import { H3, ButtonCircle, IconClose, IconArrowLeft, IconArrowRight} from "./lib"
import { Zoom, Keyboard, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css';
import * as colors from '../styles/colors'
import * as mq from '../styles/media-queries'

const photos = [
  {
    id: 'g-01',
    url:'/img/photos/Roman-Gallery-01.jpg'},
  {
    id: 'g-02',
    url:'/img/photos/Roman-Gallery-02.jpg'},
  {
    id: 'g-03',
    url:'/img/photos/Roman-Gallery-03.jpg'},
  {
    id: 'g-04',
    url:'/img/photos/Roman-Gallery-04.jpg'},
  {
    id: 'g-05',
    url:'/img/photos/Roman-Gallery-05.jpg'},
  {
    id: 'g-06',
    url:'/img/photos/Roman-Gallery-06.jpg'},
  {
    id: 'g-07',
    url:'/img/photos/Roman-Gallery-07.jpg'},
  {
    id: 'g-08',
    url:'/img/photos/Roman-Gallery-08.jpg'},
]


const GalleryPhoto = ({photo:{url, id}, handlePhotoClick}) => {
  return (
    <figure 
      onClick={(e) => handlePhotoClick(e)}
      data-id={id}
      css={{
        margin: '4px',
        transition: 'transform ease .4s',
        [mq.hover]: {
          ':hover': {
            cursor: 'pointer',
            transform: 'scale(1.05)',
          }
        }
      }}
    >
      <img css={{width: '100%', height: '100%', objectFit: 'cover'}} src={url} alt=""/>
    </figure>
  )
};

const GallerySwiper = ({photos, onSwiper}) => {
  return (
    <Swiper
      modules={[Zoom, Keyboard, Navigation]}
      zoom={{enabled: true, maxRatio: 3}}
      keyboard={{
        enabled: true,
        onlyInViewport: false,
      }}
      navigation={{
        nextEl: '.swiper-custom-next', 
        prevEl: '.swiper-custom-prev'
      }}
      spaceBetween={0}
      slidesPerView={1}
      onSwiper={onSwiper}
      css={{
        maxWidth: '1200px',
        '& .swiper-custom-prev, & .swiper-custom-next': {
          position: 'absolute',
          bottom: '50%',
          zIndex: '10',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '& .swiper-custom-prev': {
          left: '16px',
        }, 
        '& .swiper-custom-next': {
          right: '16px',
        },
        '& .swiper-custom-prev.swiper-button-disabled, & .swiper-custom-next.swiper-button-disabled': {
          opacity: '.35',
          cursor: 'auto',
          pointerEvents: 'none',
        },
        '.swiper-wrapper': { 
          alignItems: 'center'
        }
      }}
    >
      {photos.map(photo => {
        return (
          <SwiperSlide key={photo.id}>
            <div 
              className='swiper-zoom-container'
              css={{
                width: '100%',
                maxHeight: '800px',
                height: '100vh',
                margin: '0 auto',
              }} 
            >
              <img 
                src={photo.url} 
                alt={`Foto`}
                css={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }} 
              />
            </div>
          </SwiperSlide>
        )
      })}
      <ButtonCircle className="swiper-custom-prev">
        <IconArrowLeft fill={colors.white}/>
      </ButtonCircle>
      <ButtonCircle className="swiper-custom-next">
        <IconArrowRight fill={colors.white}/>
      </ButtonCircle>
    </Swiper>
  )
};


const GalleryModal = ({closeModal, swiperState, setSwiper}) => {
  React.useEffect(() => {
    function handleKeyup({key}) {
      if(swiperState !== 'active' || key !== 'Escape') return 
      closeModal();
    } 
    window.addEventListener('keyup', handleKeyup)
    return () => window.removeEventListener('keyup', handleKeyup)
  })
  return (
    <div className="modalGallery" 
        css={[
          {
            backdropFilter: 'blur(50px)',
            backgroundColor: 'rgba(0,0,0,.2)',
            height: '100vh',
            width: '100vw',
            position: 'fixed',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            pointerEvents: 'none',
            opacity: 0,
            transition: 'opacity ease-out .2s',
            zIndex: 5,
          },
          swiperState === 'active' ? {
            pointerEvents: 'all',
            opacity: 1,
          } : null
        ]}
      >
        <div css={{
          position: 'absolute',
          top: '20px',
          right: '16px',
          zIndex: '5',
        }}>
          <ButtonCircle onClick={closeModal}>
            <IconClose fill={colors.white}/>
          </ButtonCircle>
        </div>
        <GallerySwiper onSwiper={setSwiper} photos={photos}/>
      </div>
  )
}

export default function Gallery() {
  const [swiperState, setSwiperState] = React.useState('idle');
  const [swiper, setSwiper] = React.useState(null);

  function handlePhotoClick(e) {
    document.body.classList.add('no-scroll')
    const photoID = e.currentTarget.dataset.id;
    const index = photos.findIndex(photo => photo.id === photoID);
    swiper.slideTo(index, 0);
    setSwiperState('active');
  }

  function closeModal() {
    document.body.classList.remove('no-scroll')
    setSwiperState('closed');
  }

  return (
    <section 
    className="max-width"
      css={{
        width: '100%',
        boxSizing: 'border-box',
        padding: '0 15px',
        marginBottom: '180px', 
        textAlign: 'center',
        [mq.large]: {
          textAlign: 'left',
        }
    }}>
      <GalleryModal photos={photos} setSwiper={setSwiper} swiperState={swiperState} closeModal={closeModal}/>
      <H3>Impressions</H3>
      <div css={{
        '& figure': {
          minHeight: '150px',
          '& img': {
            borderRadius: '10px',
            height: '100%',
            objectFit: 'cover',
            boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.16)'
          }
        }
      }}>
        <div css={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridGap: '10px',
          marginBottom: '10px'
        }}>
        { photos.slice(0, 3).map((photo, i) => <GalleryPhoto key={i} photo={photo} handlePhotoClick={handlePhotoClick}/>) }
        </div>
        <div css={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridGap: '10px',
          marginBottom: '10px'
        }}>
          { photos.slice(3, 5).map((photo, i) => <GalleryPhoto key={i} photo={photo} handlePhotoClick={handlePhotoClick}/>) }
        </div>
        <div css={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridGap: '10px',
        }}>
          { photos.slice(5, 8).map((photo, i) => <GalleryPhoto key={i} photo={photo} handlePhotoClick={handlePhotoClick}/>) }
        </div>
      </div>
    </section>
  );
};