/** @jsx jsx */
import { jsx } from "@emotion/react"
import React from 'react'
import * as colors from '../styles/colors'
import * as mq from '../styles/media-queries'
import { Button, ButtonCircle, H3, H4, IconClose, Text, TextHashtag } from "./lib"
import { KeynoteCard } from "./cards"
import { ContactModal } from "./contact"
import keynotes from "../data/keynotes.json"
import hastags from "../data/hashtags.json"

export const  KeynoteModal = ({keynote = {}, handleModal, keynoteModal}) => {
  if(keynoteModal === 'open') {
    document.body.classList.add('no-scroll')
  } else {
    document.body.classList.remove('no-scroll')
  }

  return (
    <div css={[
      {
        opacity: 0,
        width: '100vw',
        height: '100vh',
        position: "fixed",
        top: 0,
        left: 0,
        overflowY: 'scroll',
        backdropFilter: 'blur(50px)',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: '5',
        pointerEvents: 'none',
        transition: 'opacity ease 0.4s',
      },
      keynoteModal === 'open' ? {
        opacity: 1,
        pointerEvents: 'all'
      } : null
    ]}>
      <div css={{ 
          width: '100%',
          height: '72px',
          maxWidth: '1180px',
          margin: '0 auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',          
      }}>
        <ButtonCircle onClick={handleModal} css={{ marginRight: '16px'}}>
          <IconClose fill={colors.white}/>
        </ButtonCircle>
      </div>
      <div css={{
        [mq.large]:{
          display: 'flex',
          alignItems: 'center',
          padding: '0 30px',
          marginTop: '10vh',
          maxWidth: '880px',
          margin: '10vh auto 0',
        }
      }}>
        <figure css={[
          {
            maxWidth: '300px',
            width: '50%',
            margin: '40px auto 80px',
            transition: 'transform ease .4s',
            [mq.medium]: {
              width: '30%'
            } 
          },
          keynoteModal === 'closed' ? {
            transform: 'translateY(50px)',
          } : null
        ]}>
          <img css={{
              borderRadius: '10px', 
              height: '100%', 
              objectFit: 'cover',
              boxShadow: '0px 30px 60px rgba(0, 0, 0, 0.16)',
            }} 
            src={keynote.img} alt={keynote.title}/>
        </figure>
        <div css={{
          padding: '0 20px',
          [mq.medium]: {
            display: 'flex',
            flexDirection: 'column',
            padding: '0 80px',
            '& p': {
              fontSize: '16px'
            }
          },
          [mq.large]: {
            width: '54%',
            padding: '0 20px 0 0',
          }
        }}>
          <H4 css={{margin: '0px'}}>{keynote.title}</H4>
          <Text css={{marginBottom: '40px'}}>{keynote.text}</Text>
          <ContactModal>
            <Button 
              css={{
                marginBottom: '100px',
                [mq.medium]: {
                  alignSelf: 'center'
                },
                [mq.large]: {
                  alignSelf: 'flex-start',
                }
              }}
            >
            Contact Roman</Button>
          </ContactModal>
        </div>
      </div>
    </div>
  )
}


export const Keynotes = () => {
  const [keynote, setKeynote] = React.useState({});
  const [keynoteModal, setModalState] = React.useState('closed');

  function handleKeynote(e) {
    const {id} = e.currentTarget.dataset
    const keynote = keynotes.find(keynote => keynote.id === id);
    if(!keynote) return console.error('Keynote not found')

    setKeynote(keynote);
    handleKeynoteModal();
  }

  function handleKeynoteModal() {
    setModalState(keynoteModal === 'closed' ? 'open' : 'closed');
  }

  return (
    <section
      id="keynotes"
      css={{
        paddingTop: '80px',
        marginBottom: '180px'
    }}>
      <KeynoteModal keynote={keynote} keynoteModal={keynoteModal} handleModal={handleKeynoteModal}/>
      <div 
      css={{
        padding: '0 30px',
        margin: '0 auto',
        maxWidth: '800px',
        textAlign: 'center',
        marginBottom: '40px',
      }}>
        <H3>Keynote presentations</H3>
        <Text>
          Roman takes the audience on a rollercoaster ride to the future, showcasing new technologies, ideas, and the power of make. He shares his experience to inspire the audience to shape the world and participate in creating their future.
        </Text>
      </div>
      <div css={{
        overflowX: 'auto',
      }}>
        <div css={{
          width: 'max-content',
          display: 'flex',
          flexWrap: 'wrap',
          paddingLeft: '12px',
          marginBottom: '40px',
          [mq.large]: {
            margin: '0 auto 40px',
          }
        }}>
          {keynotes.map(({id, title, img}) => {
            return (
              <KeynoteCard key={id} id={id} img={img} handleKeynote={handleKeynote}  alt={title}/>
            )})
          }
        </div>
      </div>
      <div css={{
          textAlign: 'center',
          padding: '0 30px',
          margin: '0 auto',
          maxWidth: '800px',
        }}>
        <H4 fColor= {colors.hanPurple} fStyle='italic'>WE CUSTOMIZE ALL TOPICS TO FIT YOUR EVENT.</H4>
        <Text>
          Every keynote presentation is unique and will be adapted to your event's theme, industry and purpose. Let's talk to discuss how startup thinking can enrich your conference or event.
        </Text>
        <div css={{
          marginBottom: '40px',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-evenly',
        }}>
          {hastags.map((hashtag, i) => <TextHashtag key={i}>#{hashtag}</TextHashtag>)}
        </div>
        <a href="https://meetings.hubspot.com/roman29" target="_blank" rel="noreferrer">
          <Button>Schedule a Zoom</Button>
        </a>
      </div>
    </section>
  )
}