/** @jsx jsx */
import { jsx } from "@emotion/react";
// eslint-disable-next-line no-unused-vars
import React from 'react';
import * as colors from "../styles/colors"
import * as mq from "../styles/media-queries"
import { ConvertionCard } from "../components/cards";
import { Button, H3, Text, ALink } from "../components/lib";
import Footer from "../components/Footer";
import Gallery from "../components/Gallery";
import { ContactSection, ContactModal } from "../components/contact";
import Header from "../components/Header";
import { Keynotes } from "../components/keynotes";
import { Hero } from "../components/hero";
import Workshops from "../components/Workshops";
import Experience from "../components/Experience";
import { Experiences } from "../components/experiences";
import { SocialMedia } from "../components/socialMedia";


export default function HomeScreen() {

  return (
    <>
      <Header/>
      <main>
        <Hero/>
        <ConvertionCard 
          id="be-inspired"
          img={<img src="/img/photos/Roman-Be-Inspired.jpg" alt=""/>}
          title={
            <H3 fColor={colors.hanPurple} fStyle='italic'>
              BE INSPIRED! <br/> NEVER STOP LEARNING!
            </H3>
          }
          text={
            <div>
              <Text>
                Roman Weishäupl is President, Founder and VP of Product at <ALink href="https://www.saferoom.io/" target='_blank'>SafeRoom</ALink> Technologies Inc. 
                Roman has been living in Silicon Valley for over 10 years. He has co-founded two companies and released three products. He advises C-level executives on how to think like a start-up and promote lean, agile and design thinking methodologies in today's corporate environment. As Director of <ALink href="https://www.futurecandy.com/en/" target='_blank'>Future Candy</ALink> in San Francisco, Roman brings the expertise and operating principles of the Silicon Valley to Germany.
              </Text>
              <Text>
                Before he moved to San Francisco he was Global Innovation Advisor for <ALink href="https://www.trendone.com/" target='_blank'>TrendONE</ALink> where he was leading more than 80 trends scouts all over the world.
              </Text>
              <Text>
                Roman studied International Business at University of Applied Science in Bremen and Universiti Putra Malaysia. He gained additional work experience in Malaysia and China where he was leading a team of more than 80 trend scouts from all over the world.
              </Text>
          </div>
          }
        />
        <Keynotes/>
        <Workshops/>
        <Experiences/>
        <ConvertionCard
          id="consultancy"
          title={
            <H3 fColor={colors.hanPurple}>Advisory & Consulting</H3>
          }
          img={<img 
            css={{objectPosition: '0px -50px', [mq.large]: {objectPosition: '-60px 0px'} }}
            src="/img/photos/Roman-Weishaupl-Profile.jpg" alt="Roman consulting & advisory"/>}
          text={
            <div>
              <Text>
                Working together directly to empower your ideas, projects or value proposition and benefit from his experience of years in the Valley.
              </Text>
              <Text>
                Supporting entrepreneurs and managers on their most difficult and grueling life path helping clients around the globe to drive innovation processes forward. Always giving an honest opinion on your startup or new venture and helping to solve your current issue.
              </Text>
              <Text>
                Move your business forward with regular consultancy on projects or business in general. 
              </Text>
              <Text>
                Let's discuss how to help and move your business forward.
              </Text>
            </div>
          }
          button={
            <ContactModal>
              <Button css={{margin: '20px 0'}}>Contact Roman</Button>
            </ContactModal>
          }
        />
        <Experience/>
        <Gallery/>
        <SocialMedia/>
        <ContactSection/>
      </main>
      <Footer/>
    </>
  )
}