import React from "react"
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from "react-router-dom"
import HomeScreen from "./screens/Home"
import NotFoundScreen from "./screens/NotFound";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<HomeScreen/>}/>
      <Route path="*" element={<NotFoundScreen/>}/>
    </>
  )
)

function App() {
  return (
    <RouterProvider router={router}/>
  );
}

export default App;
