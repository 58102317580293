/** @jsx jsx */
import { jsx } from "@emotion/react"
import { H3, Text, Button } from '../components/lib'
import { IconInstagram, IconLinkedIn, IconTwitter } from "./Footer";
import * as colors from "../styles/colors"
import * as mq from "../styles/media-queries"


export const SocialMedia = () => {
  return (
    <section css={{marginBottom: '180px'}}>
      <div css={{
          margin: '0 auto 40px',
          padding: '0 30px',
          maxWidth: '800px',
          textAlign: 'center',
        }}>
        <H3 css={{margin: '0'}}>Follow Roman!</H3>
        <Text>Stay updated, and get in touch via Social Media. See his Instagram Stories to get impressions from his work.</Text>
      </div>
      <div css={{
        margin: ' 0 auto',
        padding: '0 30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        maxWidth: '800px',
        [mq.medium]: {
          flexDirection: 'row'
        },
        '& a': {
          display: 'flex',
          alignContent: 'center',
          textDecoration: 'none',
          justifyContent: 'center',
          color: 'white',
          margin: '12px 0',
          '& svg': {
            marginRight: '12px'
          }
        }
      }}>
        <Button as="a" href="https://www.twitter.com/rexromanum" target="_blank" rel="noreferrer">
          <IconTwitter fill={colors.white}/>
          Twitter
        </Button>
        <Button as="a" href="https://www.instagram.com/romanweishaupl/" target="_blank" rel="noreferrer">
          <IconInstagram fill={colors.white}/>
          Instagram
        </Button>
        <Button as="a" href="https://www.linkedin.com/in/weishaupl/" target="_blank" rel="noreferrer">
          <IconLinkedIn fill={colors.white}/>
          LinkedIn
        </Button>
      </div>
    </section>
  )
}