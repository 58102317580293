/** @jsx jsx */
import { jsx } from "@emotion/react"
import React from 'react';
import { FormContact } from "./form";
import { H3,FlexColumn, Button, H4, ButtonCircle, IconClose } from "./lib";
import * as mq from "../styles/media-queries"
import * as colors from "../styles/colors"

export const ContactCard = () => {
  return (
    <div 
      className="contact-card"
      css={{
        minWidth: '240px',
        height: 'fit-content',
        backgroundColor: colors.white,
        border: `solid 1px ${colors.gray10}`,
        borderRadius: '12px',
        [mq.medium]: {
          width: '40%'
        }
      }}
    >
      <FlexColumn css={{
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: '36px 22px 36px'
      }}>
        <FlexColumn css={{
        alignItems: 'center',
        marginBottom: '40px'
        }}>
          <figure css={{
            width: '80px',
            height: '80px',
            border: `solid 1px ${colors.gray10}`,
            borderRadius: '50%',
            overflow: 'hidden',
            '& img': {
              height: '100%',
              objectFit: 'cover',
            }
          }}>
            <img src="/img/photos/Roman-Weishaupl-Profile.jpg" alt="Roman Weishäupl"/>
          </figure>
          <H4 fontSize='18px'>Roman Weishäupl</H4>
        </FlexColumn>
        <a css={{width: '100%'}} href="https://meetings.hubspot.com/roman29" target="_blank" rel="noreferrer">
          <Button>Schedule a Zoom</Button>
        </a>
      </FlexColumn>
    </div>
  )
};

export const ContactSection = () => {
  return (
    <div css={{
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '0 30px',
      [mq.large]: {
        padding: '0 50px',
      }
    }}>
      <div css={{
        marginBottom: '56px',
        textAlign: 'center'
      }}>
        <H3>Contact</H3>
      </div>
      <div css={{
        marginBottom: '112px',
        '> div:first-of-type': {
          marginBottom: '20px',
        },
        [mq.medium]: {
          display: 'flex',
          justifyContent: 'space-evenly',
          '& .form-contact': {
            width: '45%',
            maxWidth: '400px',
          },
          '& .contact-card':{
            maxWidth: '270px',
          },
        },
        [mq.large]: {
          justifyContent: 'space-evenly',
        }
      }}>
        <ContactCard/>
        <FormContact/>
      </div>
    </div>
  )
};


export const ContactModal = ({children}) => {
  const [modalState, setModalState] = React.useState('closed');

  function handleModal() {
    modalState === 'open' ? setModalState('closed') : setModalState('open')
  }

  if(modalState === 'open') {
    document.body.classList.add('no-scroll')
  } else {
    document.body.classList.remove('no-scroll')
  }
  
  return (
    <div>
      <div css={[
        {
          width: '100vw',
          height: '100vh',
          paddingTop: '40px',
          position: 'fixed',
          top: 0,
          left: 0,
          backdropFilter: 'blur(50px)',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          zIndex: '6',
          overflowY: 'scroll',
          opacity: '0',
          pointerEvents: 'none',
          transition: 'opacity ease 0.4s',
        },
        modalState === 'open' ? {
          opacity: '1',
          pointerEvents: 'all',
        } : null
      ]}>
        <div css={{
          maxWidth: '1200px',
          display: 'flex', 
          justifyContent: 'flex-end',
          margin: '0 auto 40px',
        }}>
          <ButtonCircle onClick={handleModal} css={{marginRight: '30px', alignSelf: 'right'}}>
            <IconClose fill="white"/>
          </ButtonCircle>
        </div>
        <ContactSection/>
      </div>
      <div 
        css={{
          display: 'flex',
          justifyContent: 'center'
        }}
        onClick={handleModal}
      >
        {children}
      </div>
    </div>
  )
};