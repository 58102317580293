/** @jsx jsx */
import { jsx } from "@emotion/react"
import { Text } from "./lib"
import * as colors from "../styles/colors"
import * as mq from "../styles/media-queries"

export const IconInstagram = ({width = '24px', height = '24px', fill = colors.hanPurple}) => {
  return (
    <svg css={{fill}} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <g id="ic-instagram" transform="translate(-3.602 -3.359)">
        <rect id="Box" width="24" height="24" transform="translate(3.602 3.359)" fill="none"/>
        <path id="path" d="M10.427,36.94a5.383,5.383,0,1,0,5.383,5.383A5.374,5.374,0,0,0,10.427,36.94Zm0,8.883a3.5,3.5,0,1,1,3.5-3.5,3.506,3.506,0,0,1-3.5,3.5Zm6.859-9.1a1.256,1.256,0,1,1-1.256-1.256A1.253,1.253,0,0,1,17.286,36.72Zm3.565,1.274a6.213,6.213,0,0,0-1.7-4.4,6.254,6.254,0,0,0-4.4-1.7c-1.733-.1-6.929-.1-8.662,0a6.245,6.245,0,0,0-4.4,1.691,6.234,6.234,0,0,0-1.7,4.4c-.1,1.733-.1,6.929,0,8.662a6.213,6.213,0,0,0,1.7,4.4,6.262,6.262,0,0,0,4.4,1.7c1.733.1,6.929.1,8.662,0a6.213,6.213,0,0,0,4.4-1.7,6.254,6.254,0,0,0,1.7-4.4C20.95,44.918,20.95,39.727,20.851,37.994ZM18.612,48.511a3.543,3.543,0,0,1-2,2c-1.382.548-4.661.422-6.189.422s-4.811.122-6.189-.422a3.543,3.543,0,0,1-2-2c-.548-1.382-.422-4.661-.422-6.189s-.122-4.811.422-6.189a3.543,3.543,0,0,1,2-2c1.382-.548,4.661-.422,6.189-.422s4.811-.122,6.189.422a3.543,3.543,0,0,1,2,2c.548,1.382.422,4.661.422,6.189S19.16,47.134,18.612,48.511Z" transform="translate(5.176 -26.886)"/>
      </g>
    </svg>
  )
};

export const IconTwitter = ({width = '24px', height = '24px', fill = colors.hanPurple}) => {
  return (
    <svg css={{fill}} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 23.807 19.345">
      <path id="Path_1588" data-name="Path 1588" d="M82.487,117.8a13.8,13.8,0,0,0,13.9-13.9c0-.211,0-.422-.014-.631a9.938,9.938,0,0,0,2.437-2.528,9.749,9.749,0,0,1-2.805.769,4.9,4.9,0,0,0,2.147-2.7,9.789,9.789,0,0,1-3.1,1.186,4.889,4.889,0,0,0-8.324,4.455,13.867,13.867,0,0,1-10.067-5.1,4.888,4.888,0,0,0,1.512,6.52,4.848,4.848,0,0,1-2.217-.611v.062a4.886,4.886,0,0,0,3.919,4.788,4.877,4.877,0,0,1-2.206.084,4.89,4.89,0,0,0,4.563,3.392,9.8,9.8,0,0,1-6.066,2.1A9.941,9.941,0,0,1,75,115.6a13.828,13.828,0,0,0,7.487,2.19" transform="translate(-75 -98.45)" fill={fill}/>
    </svg>
  )
}

export const IconLinkedIn = ({width = '21px', height = '21px', fill = colors.hanPurple}) => {
  return (
    <svg css={{fill}} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21 21">
      <path id="linkedin-in" d="M4.7,21.01H.347V6.989H4.7ZM2.521,5.077A2.533,2.533,0,1,1,5.043,2.531,2.543,2.543,0,0,1,2.521,5.077ZM21,21.01H16.651V14.185c0-1.627-.033-3.712-2.264-3.712-2.264,0-2.61,1.767-2.61,3.6V21.01H7.428V6.989H11.6V8.9h.061a4.575,4.575,0,0,1,4.119-2.264c4.406,0,5.216,2.9,5.216,6.67v7.7Z" transform="translate(0 -0.01)" fill={fill}/>
    </svg>
  )
}


export default function Footer() {

  return(
    <footer css={{
      borderTop: `solid 2px ${colors.hanPurple}`,
    }}>
      <div css={{
        width: '100%',
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '30px 20px 12px',
        boxSizing: 'border-box',
        backgroundColor: colors.white,
        [mq.large]: {
          display: 'flex',
          flexWrap: 'wrap',
          '& div': {
            marginRight: '20px',
          },
          '& p': {
            fontSize: '16px',
          }
        },
        [mq.xLarge]: {
          justifyContent: 'space-between',
          '& div': {
            margin: '0',
          }
        }
      }}>
        <div>
          <Text fColor={colors.gray87}><strong>Owner: Roman Weishäupl</strong></Text>
          <Text>Tax ID/USt. DE325940807 <br/> © {new Date().getFullYear()} Be inspired! Never stop Learning!</Text>
        </div>
        <div>
          <Text fColor={colors.gray87}><strong>USA</strong></Text>
          <Text>PO Box 192932 <br/> San Francisco, CA 94119</Text>
        </div>
        <div>
          <Text fColor={colors.gray87}><strong>Germany</strong></Text>
          <Text>Weishäupl Ventures <br/> Auf dem Lattigkopf 17 <br/> 61118 Bad Vilbel</Text>
        </div>
        <div>
          <Text fColor={colors.gray87}><strong>Social Media</strong></Text>
          <div 
            css={{
              display: 'flex',
            '& a': {
              padding: '4px',
              width: 'fit-content'
            }
          }}>
          <a href="https://www.linkedin.com/in/weishaupl/" target="_blank" rel="noreferrer">
            <IconLinkedIn/>
          </a>
          <a href="https://www.instagram.com/romanweishaupl/" target="_blank" rel="noreferrer">
            <IconInstagram/>
          </a>
          <a href="https://www.twitter.com/rexromanum" target="_blank" rel="noreferrer">
            <IconTwitter/>
          </a>
          </div>
        </div>
        <div css={{
          'a': {
            color: colors.hanPurple,
            textDecoration: 'none'
          }
        }}>
          <Text>Designed and developed by <a href="https://www.carloshm.com/" target="_blank" rel="noreferrer"><strong>Carlos HM</strong></a></Text>
        </div>
      </div>
    </footer>
  )
};
