/** @jsx jsx */
import { jsx } from "@emotion/react"
import { H3, Text, Button } from "./lib"
import { WorkshopCard } from "./cards"
import workshops from "../data/workshops.json"
import { ContactModal } from "./contact"

export default function Workshops() {
  return (
    <section
      className="max-width"
      id="workshops"
      css={{
        paddingTop: '80px',
        marginBottom: '180px'
    }}>
      <div css={{
        maxWidth: '800px',
        margin: '0 auto',  
        textAlign: 'center', 
        marginBottom: '40px'
      }}>
        <H3>Innovation Workshops</H3>
        <Text>Hands on Silicon Valley deep work. Leverage the  power of your team to create something new and innovative.</Text>
      </div>
      <div 
        css={{
          width: '100%',
          overflowX: 'auto',
          marginBottom: '20px',
        }}
      >
        <div css={{
          display: 'flex',
          flexWrap: 'nowrap',
          marginBottom: '20px',
          '::before':{
              content: '""',
              minWidth: '12px',
            },
            '::after':{
              content: '""',
              minWidth: '12px',
            },
          '& .workshop-card': {
            margin: '0 10px'
          }
        }}>
          {workshops.map(({title, description, img}, i) => <WorkshopCard key={i} title={title} description={description} img={img}/>)}
        </div>
      </div>
      <div css={{padding: '0 30px'}}>
        <ContactModal>
          <Button>Learn about workshops</Button>
        </ContactModal>
      </div>
    </section>
  )
}