/** @jsx jsx */
import { jsx } from "@emotion/react"
import { H4, Text } from "./lib"
import * as colors from "../styles/colors"
import * as mq from "../styles/media-queries"

export const ConvertionCard = ({id, img, title, text, button}) => {
  return (
    <section
      id={id}
      className="max-width"
      css={{
        marginBottom: '100px',
        padding: '0 15px',
        [mq.large]: {
          display: 'flex',
          justifyContent: 'flex-end'
        }
      }}
    >
      <figure css={{
        aspectRatio:' 3/1.7',
        overflow: 'hidden',
        borderRadius: '8px',
        width: '95%',
        margin: '0 auto',
        transform: 'translateY(50px)',
        boxShadow: '0px 30px 50px 0px rgba(0, 0, 0, 0.16)',
        '& img': {
          borderRadius: '8px',
          objectFit: 'cover',
          height: '100%'
        },
        [mq.large]: {
          width: '45%',
          maxWidth: '300px',
          aspectRatio: '3 / 4',
          height: 'fit-content',
          transform: 'translate(20%, 13%)',
        },
        [mq.xLarge]: {
          transform: 'translate(20%, 6%)',
        }
      }}>
        {img}
      </figure>
      <div css={{
        padding: '12px',
        background: colors.cultured,
        borderRadius: '8px',
        '& h3': {
          marginTop: '80px',
          [mq.large]: {
            margin: '20px 0'
          }
        },
        '& p': {
          color: colors.gray87,
          [mq.large]: {
            fontSize: '18px'
          }
        },
        [mq.medium]: {
          padding: '12px 30px'
        },
        [mq.large]: {
          width: '85%',
          paddingLeft: '13%',
        }
      }}>
        {title}
        {text}
        {button}
      </div>
    </section>
  )
}

export const KeynoteCard = ({id, img, title, handleKeynote}) => {
  return (
    <figure 
      css={{
        maxWidth: '200px',
        width: '43vw',
        marginRight: '12px',
          transition: 'transform ease .4s',
        [mq.hover]: {
          cursor: 'pointer',
          padding: '20px 0',
          ':hover': {
            transform: 'scale(1.05)',
          }
        },
        [mq.xLarge]: {
          maxWidth: '280px',
          width: '22vw',
          marginRight: '20px'
        }
        }}
      data-id={id} 
      onClick={handleKeynote}
    >
      <img src={img} alt={title}
        css={{
          borderRadius: '10px'          
        }}
      />
    </figure>
  )
}

export const WorkshopCard = ({img, title = 'Title', description = 'Description' }) => {
  return (
    <div
      className="workshop-card" 
      css={{
        width: '85%',
        minWidth: '330px',
        maxWidth: '400px',
        [mq.large]: {
          minWidth: '250px',
        }
      }}
    >
      <figure css={{
        aspectRatio: '2 / 1',
        overflow: 'hidden',
        borderRadius: '10px',
        '& img': {
          height: '100%',
          objectFit: 'cover'
        }
      }}>
        <img src={img} alt={title}/>
      </figure>
      <div css={{
        [mq.large]: {
          '& h4': {
            fontSize: '20px',
            marginBottom: '10px'
          },
          '& p': {
            fontSize: '16px'
          }
        }
      }}>
        <H4>{title}</H4>
        <Text>{description}</Text>
      </div>
    </div>
  )
}