/** @jsx jsx */
import { jsx } from "@emotion/react";
import { ContactModal } from "./contact";
import { H2, Text, Button } from "./lib";
import * as mq from '../styles/media-queries';

export const Hero = () => {
  return (
    <section className="max-width" css={{
      marginTop: '150px',
      padding: '0 30px',
      marginBottom: '100px',
      [mq.large]: {
        display: 'flex',
        flexDirection: 'row-reverse',
      }
    }}>
      <figure css={{
        marginBottom: '80px',
        filter: 'drop-shadow(-40px 40px 90px rgba(79, 59, 235, 0.5))',
        [mq.medium]: {
          width: '70%',
          margin: '0 auto 80px'
        }, 
        [mq.large]: {
          width: '40%',
          margin: '40px auto 80px'
        }
        }}>
        <img src="/img/photos/Roman-World-of-Innovation.png" alt="Roman Weishäupl"/>
      </figure>
      <div css={{
          [mq.large]: {
            width: '50%',
            marginTop: '10%',
            '& h2': {
              margin: 0,
            },
            '& p': {
              margin: '10px 0',
            }
          },
          [mq.xLarge]: {
            '& h2':{
              fontSize: '46px',
            }
          },
        }}>
        <H2>Welcome to the world of innovation.</H2>
        <Text>Make your event special by bringing the power of the Silicon Valley to your audience, clients or colleagues. Interactive and customized to your needs. Let's talk!</Text>
        <div css={{
          margin: '60px 0',
          '& button': {
            marginBottom: '15px'
          },
          [mq.medium]: {
            display: 'flex',
            justifyContent: 'center',
            '& >a:first-of-type': {
              marginRight: '40px',
            }
          },
          [mq.large]: {
            margin: '30px 0'
          }
        }}>
          <a href="https://meetings.hubspot.com/roman29" target="_blank" rel="noreferrer">
            <Button>Schedule a Zoom</Button>
          </a>
          <ContactModal>
            <Button variant='secondary'>Contact</Button>
          </ContactModal>
        </div>
      </div>
    </section>
  )
}