/** @jsx jsx */
import { jsx } from "@emotion/react"
// eslint-disable-next-line no-unused-vars
import React from 'react'
import * as colors from '../styles/colors'
import * as mq from '../styles/media-queries'
import { Link } from 'react-router-dom'
import Header from "../components/Header"
import Footer from "../components/Footer"

function NotFoundScreen() {
  return (
    <>
      <Header/>
      <main>
        <div 
          className='max-width'
          css={{
            height: '100vh',
            maxHeight: '800px',
            display: 'flex',
            flexDirection: 'column',
            padding: '30px',
          }}
        >
          <div 
            css={{
              marginTop: '15vh',
              '& > p': {
                fontFamily: "'Poppins', sans-serif",
                fontSize: '26px',
                fontWeight: '600',
                color: colors.gray87,
                marginBottom: '10px',
                [mq.medium]: {
                  fontSize: '30px',
                },
                [mq.large]: {
                  fontSize: '40px',
                },
              '& > a': {
                  fontFamily: "'Poppins', sans-serif",
                  color: colors.hanPurple,
                  transition: 'color ease .4s',
                ':hover': {
                  color: colors.midnightBlue,
                }
              }
            },
            }}
          >
            <p>Sorry, nothing to see here...</p>
            <p>But you can know more about Roman in the <Link to="/">home page.</Link></p>
          </div>
        </div>
      </main>
      <Footer/>
    </>
  )
}

export default NotFoundScreen