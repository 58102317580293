 /** @jsx jsx */
import { jsx } from "@emotion/react";
import React from "react";
import {Text, Label, Input, Textarea, FlexColumn, Button } from './lib';
import * as colors from "../styles/colors";
import { send } from "@emailjs/browser";

const IconCheck = ({width = '24px', height = '24px', fill = colors.black}) => {
  return (
    <svg css={{fill}} id="ic-check" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <rect id="Box" width="24" height="24" fill="none"/>
      <path id="Path" d="M5.975,77.958.258,72.241A.88.88,0,0,1,.258,71L1.5,69.753a.879.879,0,0,1,1.244,0L6.6,73.6l8.249-8.249a.88.88,0,0,1,1.244,0L17.333,66.6a.88.88,0,0,1,0,1.244L7.218,77.958a.879.879,0,0,1-1.244,0Z" transform="translate(3.41 -59.215)"/>
    </svg>
  )
};

function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const InputLabel = ({label = 'Label', width = '100%', id, type = 'text', value, onChange, required = false}) => {
  // status: idle active ok error
  const [inputState, setInputState] = React.useState({state: 'idle', message: null});

  function handleActive() {
    setInputState({state:'active'});
  }

  function handleLeave(e) {
    if(!e.target.value.length) return setInputState({state: 'idle'})
    if(type === 'email' && !validateEmail(e.target.value)) {
       return setInputState({state:'error', message: 'Please enter a valid email.'});
    }
    if(e.target.value.length) {
      setInputState({state: 'ok'});
    }    
  }

  return (
    <FlexColumn css={{
      width,
      '& .input-error': {
        margin: '5px 0',
        color: colors.tartOrange,
        opacity: '.5',
      }
    }}>
      <Label variant={inputState.state} htmlFor={id}>{label}</Label>
      {type === 'textarea' ? 
        <Textarea onChange={onChange} onFocus={handleActive} onBlur={handleLeave} id={id} type={type} value={value} required={required} rows="4" cols="50"></Textarea>
        : 
        <Input variant={inputState.state} onChange={onChange} onFocus={handleActive} onBlur={handleLeave} id={id} type={type} value={value} required={required}/>
      }
      {inputState.state === 'error' && <Text fontSize='12px' className='input-error'>{inputState.message}</Text>}
    </FlexColumn>
  )
};



export const FormContact = () => {
  const [formStatus, setFormStatus] = React.useState('idle');
  const [data, setData] = React.useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  })

  function resetData() {
    setData({
      name: '',
      email: '',
      phone: '',
      message: '',
    })
  }


  function handleChange(event) { 
    const {id, value} = event.target;
    setData({...data, [id]: value})
  }
  
  function handleSubmit(event) {
    event.preventDefault()
    if(!validateEmail(data.email)) return
    
    for (let key in data) {
      if(data[key.length] <= 0) return
    }

    setFormStatus('sending');
    send(
      process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
      process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID,
      {to_name: 'Roman', from_name: data.name, from_email: data.email, message: data.message, from_phone: data.phone},
      process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY,
    )
    .then((response) => {
      setFormStatus('sent')
      console.log('Success!', response.status, response.text)
    })
    .catch((err) => {
      setFormStatus('idle')
      console.log('Failed...', err)
    })
  }


  return(
    <div
      className="form-contact"    
      css={{
        padding: '15px 25px 40px',
        backgroundColor: colors.white,
        position: 'relative',
        border: `solid 1px ${colors.gray10}`,
        borderRadius: '10px'
      }}
    >
      <form 
        onSubmit={handleSubmit}
        autoComplete="off" 
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <InputLabel onChange={handleChange} value={data.name} label='Name' id="name" required={true}/>
        <InputLabel onChange={handleChange} value={data.email} label='Email' id="email" type="email" required={true}/>
        <InputLabel onChange={handleChange} value={data.phone} label='Phone' id="phone" type="tel"/>
        <InputLabel onChange={handleChange} value={data.message} label='Message' id="message" type="textarea" required={true}/>
        <Button css={{marginTop: '30px'}}>{formStatus === 'idle' ? 'Send message' : 'Sending...'}</Button>
      </form>
      <div 
        css={{
          opacity: `${formStatus === 'sent' ? '1' : '0'}`,
          pointerEvents: `${formStatus === 'sent' ? 'all' : 'none'}`,
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0',
          left: '0',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: colors.white,
          borderRadius: '20px',
          transition: 'opacity ease .4s'
        }}
      >
        <figure
          css={{
            width: '26%',
            alignSelf: 'center',
            marginBottom: '20px'
          }}
        >
          <IconCheck fill={colors.hanPurple} width='100%' height='100%'/> 
        </figure>
        <FlexColumn 
          css={{width: '80%', alignItems: 'center'}}
        >
          <p css={{
            marginBottom: '10px',
            fontFamily: 'satoshi, sans-serif',
            fontWeight: '600',
            fontSize: '22px',
            color: colors.gray87,
            }}
          >{`Thanks for reaching out ${data.name}!`}</p>
          <p
            css={{marginBottom: '30px'}}
          >Roman will get back to you soon.</p>
          <Button 
            onClick={() => {
              resetData()
              setFormStatus('idle')
            }} 
            type="button" 
            css={{margin: '0 auto'}}
          >
            Got it
          </Button>
        </FlexColumn>
      </div>
    </div>
  )
};