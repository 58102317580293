/** @jsx jsx */
import { jsx } from "@emotion/react"
import styled from "@emotion/styled"
import * as colors from "../styles/colors"
import * as mq from "../styles/media-queries"

// ___________________________________________________ TITLES
export const H2 = styled.h2(({size = '30px', fontWeight = '700'}) => {
  return {
    marginBottom: '8px',
    fontSize: size,
    color: colors.gray87,
    fontWeight,
    [mq.medium]: {
      fontSize: '34px',
    }
  }
})

export const H3 = styled.h3(({size = '26px', fontWeight = '600', fColor = colors.gray87, fStyle = ''}) => {
  return {
    color: fColor,
    fontSize: size,
    fontWeight,
    fontStyle: fStyle,
    [mq.medium]: {
      fontSize: '30px'
    }
  }
})

export const H4 = styled.h4(({size = '18px', fontWeight = '600', fColor = colors.gray87, fStyle = ''}) => {
  return {
    fontSize: size,
    fontWeight,
    fontStyle: fStyle,
    color: fColor,
    [mq.medium]: {
      fontSize: '22px',
    }
  }
})

// ___________________________________________________ TEXT
export const Text = styled.p(({size = '16px', fColor = colors.gray54}) => {
  return {
    fontSize: size,
    color: fColor,
    [mq.medium]: {
      fontSize: '20px'
    }
  }
})

export const TextHashtag = styled.p({
  color: colors.gray87,
  fontWeight: '600',
  margin: '12px 2px',
  [mq.large]: {
    fontSize: '20px',
    margin: '6px 12px'
  }
})

export const Caption = styled.p(({size = '14px',  fColor = colors.gray54, marginBottom = '5px'}) => {
  return {
    marginBottom,
    fontSize: size,
    fontWeight: '600',
    color: fColor,
    [mq.medium]: {
      fontSize: '16px'
    }
  }
});

// ___________________________________________________ LINKS
export const ALink = styled.a(()=> {
  return {
    color: colors.hanPurple,
    textDecoration: 'underline',
    fontWeight: '600',
    transition: 'color ease .4s',
    [mq.hover]: {
      ':hover': {
        color: colors.midnightBlue
      }
    }
  }
})

// ___________________________________________________ BUTTONS
const buttonVariants = {
  primary: {
    color: colors.white,
    backgroundColor: colors.hanPurple,
    boxShadow: '0px 25px 30px 0px rgba(79,59,235,0.15)',
    [mq.hover] :{
      ':hover': {
        backgroundColor: colors.midnightBlue,
      }
    }
  },
  secondary: {
    color: colors.hanPurple,
    border: `1px solid ${colors.hanPurple}`,
    backgroundColor: 'rgba(0,0,0,0)',
    [mq.hover] :{
      ':hover': {
        color: colors.midnightBlue,
        borderColor: colors.midnightBlue,
      }
    }
  },
  futureCandy: {
    color: colors.gray87,
    backgroundColor: colors.sunglow,
    boxShadow: '0px 25px 30px 0px rgba(255, 205, 65, 0.15)',
    [mq.hover] :{
      ':hover': {
        backgroundColor: colors.amber,
      }
    }
  }
};

export const Button = styled.button(({width = '100%', fontSize = '18px'}) => {
  return {
    width,
    fontSize,
    fontWeight: '600',
    boxSizing: 'border-box',
    padding: '18px 36px',
    borderRadius: '6px',
    border: 0,
    transition: 'all ease .4s',
    [mq.medium]:{
      width: 'fit-content',
      fontSize: '18px',
    }
  }
}, ({variant = 'primary'}) => buttonVariants[variant]);


const buttonCircleVariants = {
  disabled: {
    opacity: '.5',
    pointerEvents: 'none',
    cursor: 'default',
  }
}

export const ButtonCircle = styled.button(() => {
  return {
  backgroundColor: 'rgb(0,0,0)', /* Fallback color */
  // eslint-disable-next-line no-dupe-keys
  backgroundColor: 'rgba(0,0,0, 0.2)',
  border: 'none',
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center', 
  backdropFilter: 'blur(30px)',
  transition: 'opacity ease .4s'
  }
}, ({variant = ''}) => buttonCircleVariants[variant]);

// ___________________________________________________ DIVs
export const FlexColumn = styled.div(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
  }
});


// ___________________________________________________ FORM
const labelVariants = {
  idle: {
    transform: 'translate(10px, 140%)',
  },
  active: {
    color: colors.hanPurple,
  },
  ok: {
  },
  error: {
    color: colors.tartOrange,
  }
};

export const Label = styled.label(({fontSize = '16px'}) => {
  return {
    width: 'fit-content',
    padding: '5px',
    fontSize,
    fontWeight: '600',
    color: colors.gray54,
    backgroundColor: colors.white,
    pointerEvents: 'none',
    transform: 'translate(10px, 40%)',
    transition: 'transform ease .2s'
  }
}, ({variant = ''}) => labelVariants[variant]);

const inputVariables = {
  error: {
    outline: `solid 2px ${colors.tartOrange}`,
  }
}

export const Input = styled.input({
  fontSize: '16px',
  outline: `solid 1px ${colors.gray10}`,
  border: 0,
  borderRadius: '2px',
  padding: '18px',
  backgroundColor: colors.white,
  ':focus-visible': {
    outline: `solid 2px ${colors.hanPurple}`,
  }
}, (({variant = ''}) => inputVariables[variant] ));

export const Textarea = styled.textarea({
  fontSize: '16px',
  outline: `solid 1px ${colors.gray10}`,
  border: 0,
  borderRadius: '2px',
  padding: '18px',
  backgroundColor: colors.white,
  resize: 'none',
  ':focus-visible': {
    outline: `solid 2px ${colors.hanPurple}`,
  }
});


// ___________________________________________________ ICONS
export const IconClose = ({width = '24px', height = '24px', fill = colors.black}) => {
  return (
    <svg css={{fill}} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <g id="ic-arrow-left" transform="translate(-3.602 -3.359)">
        <rect id="Box" width="24" height="24" transform="translate(3.602 3.359)" fill="none"/>
        <path id="Path" d="M2.433.621A1.194,1.194,0,0,0,3,.938a1.344,1.344,0,0,0,.643,0,1.138,1.138,0,0,0,.544-.3L8.88-4.059,13.565.629a1.143,1.143,0,0,0,.553.31,1.327,1.327,0,0,0,.639,0,1.183,1.183,0,0,0,.559-.318,1.183,1.183,0,0,0,.318-.559,1.327,1.327,0,0,0,0-.639,1.158,1.158,0,0,0-.306-.553L10.647-5.826l4.685-4.685a1.174,1.174,0,0,0,.308-.553,1.286,1.286,0,0,0,0-.637,1.2,1.2,0,0,0-.321-.557,1.194,1.194,0,0,0-.561-.323,1.319,1.319,0,0,0-.637,0,1.143,1.143,0,0,0-.553.31L8.88-7.589,4.187-12.278a1.124,1.124,0,0,0-.547-.3,1.388,1.388,0,0,0-.645,0,1.14,1.14,0,0,0-.563.318,1.169,1.169,0,0,0-.31.561,1.379,1.379,0,0,0,0,.643,1.114,1.114,0,0,0,.3.542L7.113-5.826,2.42-1.125a1.143,1.143,0,0,0-.3.54,1.327,1.327,0,0,0,0,.643A1.169,1.169,0,0,0,2.433.621Z" transform="translate(6.723 21.181)"/>
      </g>
    </svg>
  )
};

export const IconArrowLeft = ({width = '24px', height = '24px', fill = colors.black}) => {
  return (
    <svg css={{fill}} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <g id="ic-arrow-left" transform="translate(-3.602 -3.359)">
        <rect id="Box" width="24" height="24" transform="translate(3.602 3.359)" fill="none"/>
        <path id="Path" d="M9.938-10.626a1.388,1.388,0,0,1,.49-1l5.233-5.217a1.18,1.18,0,0,1,.87-.348,1.154,1.154,0,0,1,.862.348,1.194,1.194,0,0,1,.34.87,1.249,1.249,0,0,1-.379.885l-1.881,1.85-1.818,1.47,3.241-.126h7.763a1.258,1.258,0,0,1,.917.356,1.217,1.217,0,0,1,.364.909,1.27,1.27,0,0,1-.364.925,1.229,1.229,0,0,1-.917.372H16.894l-3.241-.142,1.8,1.486,1.9,1.834a1.18,1.18,0,0,1,.379.885,1.194,1.194,0,0,1-.34.87,1.154,1.154,0,0,1-.862.348,1.214,1.214,0,0,1-.87-.348L10.428-9.6A1.415,1.415,0,0,1,9.938-10.626Z" transform="translate(-2.336 26.055)"/>
      </g>
    </svg>
  )
};

export const IconArrowRight = ({width = '24px', height = '24px', fill = colors.black}) => {
  return (
    <svg css={{fill}} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <g id="ic-arrow-right" transform="translate(-3.602 -3.359)">
        <rect id="Box" width="24" height="24" transform="translate(3.602 3.359)" fill="none"/>
        <path id="Path" d="M25.938-10.626a1.388,1.388,0,0,0-.49-1L20.214-16.84a1.18,1.18,0,0,0-.87-.348,1.154,1.154,0,0,0-.862.348,1.194,1.194,0,0,0-.34.87,1.249,1.249,0,0,0,.379.885l1.881,1.85,1.818,1.47-3.241-.126H11.218a1.258,1.258,0,0,0-.917.356,1.217,1.217,0,0,0-.364.909A1.27,1.27,0,0,0,10.3-9.7a1.229,1.229,0,0,0,.917.372h7.763l3.241-.142-1.8,1.486-1.9,1.834a1.18,1.18,0,0,0-.379.885,1.194,1.194,0,0,0,.34.87,1.154,1.154,0,0,0,.862.348,1.214,1.214,0,0,0,.87-.348l5.233-5.2A1.415,1.415,0,0,0,25.938-10.626Z" transform="translate(-2.336 26.055)"/>
      </g>
    </svg>
  )
};